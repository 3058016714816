<template>
  <div>

    <ViewLoadingMessage :loading="loadingPage"/>

    <CRow v-if="!loadingPage">
      <CCol lg="12" class="mb-3">
        <div class="d-flex flex-row align-items-center">
          <h1 class="mr-4" v-translate>Check-ins</h1>
          <span class="mr-2" v-translate>Found %{ totalResults } results</span>
        </div>
        <TimeZoneInfo :timezone="timeZone"/>
      </CCol>
      <CCol lg="12" class="mb-4">
        <div class="d-flex flex-row flex-lg-row">

          <div class="d-flex flex-column mr-3 flex-grow-1">
            <DatePickerTimeZone
                v-if="hasPerm('checkin.fields.created_at')"
                @input="onDatePickerInput"
                :showDatePicker="true"
                :showTimeZonePicker="false"
                :timeZoneValue="timeZone"
            />
          </div>

          <div style="d-flex flex-column min-width: 240px; self-align-end" class="mr-3">
            <CInput size="sm"
                    block
                    type="text"
                    v-model="searchText"
                    class="search-field"
                    @keydown.enter="getEventCheckins"
                    v-if="hasAnyPerms(['checkin.fields.email', 'checkin.fields.pica_code'])"
            >
              <template #prepend-content>
                <CIcon name="cip-search2" size="custom" :height="14" style="color: #7E838D"/>
              </template>
            </CInput>
          </div>
          <template v-if="hasPerm('checkin.fields.available_photos_number')">
            <div class="d-flex flex-column mr-2 mt-2"><label v-translate>Check-ins with 0 photos</label></div>
            <CSwitch
                class="mx-1 mt-2" color="primary"
                variant="3d" size="sm"
                :checked.sync="searchNoPhoto"
            />
          </template>
          <div>
            <PButton
                v-if="hasPerm('checkin.export')"
                color="primary"
                class="ml-2"
                size="sm"
                @click="doExportCheckins"
                :loading-text="$gettext('Generating file...')"
                spinner-color="secondary"
                :disabled="totalResults <= 0">
              <CIcon name="cipFileDownload02" style="vertical-align: sub" class="mr-2"/>
              <translate>Export</translate>
            </PButton>
          </div>
        </div>
      </CCol>

      <CCol lg="12">
        <CCard class="no-borders">
          <CCardBody>
            <CDataTable
                class="custom-table"
                :items="rows"
                :fields="checkinsFields"
                :hover="false"
                :striped="true"
                :bordered="true"
                :small="false"
                :fixed="false"
                :loading="loadingData"
                :sorter='{ external: true, resetable: false }'
                :sorter-value.sync="sorterValue"
                @update:sorter-value="getEventCheckins()"
                :no-items-view="{ noResults: this.$gettext('no filtering results available'), noItems: this.$gettext('No data') }"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h6 v-translate>No data found</h6>
                </div>
              </template>
              <template #sorting-icon="{ state, classes }">
                <div :class="classes">
                  <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                  <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                </div>
              </template>
              <template #created_at="{ item }">
                <td>{{ item.created_at | formatDateTimeTZ(timeZone, 'L LT') }}</td>
              </template>
              <template #picacode="{ item }">
                <td>
                  <CBadge color="primary" class="badge-outline pica-code pica-code--sm">{{ item.pica_code }}</CBadge>
                </td>
              </template>
              <template #date_of_stay="{ item }">
                <td>
                  <span>
                    {{ item.start_of_stay | formatDateTimeTZ(timeZone, 'L') }}
                    -
                    {{ item.end_of_stay | formatDateTimeTZ(timeZone, 'L') }}
                  </span>
                </td>
              </template>
              <template #is_master="{ item }">
                <td class="text-center">
                  <CIcon :name="item.is_master ? 'cipDotGreen' : 'cipDotGray'"
                         size="custom-size" :height="10"/>
                </td>
              </template>
              <template #available_photos_number="{ item }">
                <td class="text-right">
                  <div class="mr-3 font-weight-semi-bold">
                    <CLink :to="routeToSearchPhotos(item.pica_code)" v-c-tooltip="{content: $gettext('Search photos')}"
                           v-if="hasPerm('dashboard.view_feature_search_photos')">
                      <CIcon name="cip-search" class="mr-3"/>
                    </CLink>
                    <CLink :to="routeToBrowsePhotos(item.pica_code)" v-c-tooltip="{content: $gettext('Browse Photo')}"
                           v-if="canBrowsePhoto">
                      <CIcon name="cip-search" class="mr-3"/>
                    </CLink>
                    {{ item.available_photos_number|number }}
                  </div>
                </td>
              </template>
              <template #tags="{ item }">
                <td>
                  {{ item.tags | tags_to_string }}
                </td>
              </template>
              <template #actions="{ item }">
                <td class="text-right">
                  <CButton size="sm" variant="outline" color="primary" class="mr-2"
                           @click="$router.push({name: 'checkin.detail', params: {id: item.id, picaServiceId: picaServiceId}})"
                           v-translate translate-context="list.actions"
                           v-if="hasPerm('checkin.see_checkin')"
                  >
                    Detail
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>

          <CCardFooter v-if="totalPages > 1">
            <CPagination
                align="center"
                @update:activePage="getEventCheckins({page: $event})"
                :active-page.sync="page"
                :pages="totalPages "/>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ViewLoadingMessage from "@/components/ViewLoadingMessage.vue";
import {checkinsFields} from "@/domain/checkin/constants";
import {mapActions, mapGetters} from "vuex";
import DatePickerTimeZone from "@/components/DatePickerTimeZone.vue";
import PButton from "@/components/PButton.vue";
import TimeZoneInfo from "@/domain/core/components/TimeZoneInfo.vue";

export default {
  name: "Checkins",
  components: {TimeZoneInfo, PButton, DatePickerTimeZone, ViewLoadingMessage},
  data() {
    return {
      loadingPage: true,
      loadingData: false,
      searchNoPhoto: false,
      searchText: null,
      sorterValue: {"asc": false, "column": "created_at"},
      dateFilter: {
        start: null,
        end: null,
      },
    }
  },
  filters: {
    tags_to_string(tags) {
      return tags ? tags.join(', ') : "–"
    }
  },
  computed: {
    ...mapGetters('event', [
      'picaServiceId', 'timeZone'
    ]),
    ...mapGetters('eventGallery', [
      'galleries',
    ]),
    ...mapGetters('checkin', [
      'checkins',
    ]),
    ...mapGetters('eventWizard', [
      'canSeePhotoSearch',
    ]),
    canBrowsePhoto() {
      return this.canSeePhotoSearch && this.hasPerm('photo_search.filter_by_pica_code')
    },
    checkinsFields() {
      let fields = []
      if (this.hasPerm('checkin.fields.id')) fields.push(checkinsFields.id)
      if (this.hasPerm('checkin.fields.email')) fields.push(checkinsFields.email)
      if (this.hasPerm('checkin.fields.created_at')) fields.push(checkinsFields.created_at)
      if (this.hasPerm('checkin.fields.pica_code')) fields.push(checkinsFields.pica_code)
      if (this.hasPerm('checkin.fields.is_master')) fields.push(checkinsFields.is_master)
      if (this.hasPerm('checkin.fields.date_of_stay')) fields.push(checkinsFields.date_of_stay)
      if (this.hasPerm('checkin.fields.available_photos_number')) fields.push(checkinsFields.available_photos_number)
      if (this.hasPerm('checkin.fields.tags')) fields.push(checkinsFields.tags)
      if (this.hasPerms(['checkin.see_checkin', 'checkin.fields.id'])) fields.push(checkinsFields.actions)
      return fields
    },
    totalPages() {
      if (!this.checkins || !this.checkins.pagination) return 0
      return this.checkins.pagination.num_pages
    },
    totalResults() {
      if (!this.checkins || !this.checkins.pagination) return 0
      return this.$options.filters.number(this.checkins.pagination.total)
    },
    page: {
      get() {
        if (!this.checkins || !this.checkins.pagination) return 0
        return this.checkins.pagination.page_number
      },
      set(val) {
        return val
      }
    },
    rows() {
      if (!this.checkins) return []
      return this.checkins.results
    },
  },
  watch: {
    searchNoPhoto: function () {
      this.getEventCheckins()
    },
  },

  async mounted() {
    this.setPicaServiceId(this.picaServiceId)
    await this.fetchConfig({type: 'checkin', id: this.picaServiceId})

    await this.getEventCheckins()
    this.loadingPage = false
  },
  methods: {
    ...mapActions('checkin', [
      'fetchCheckins',
      'exportCheckins',
      'setPicaServiceId',
    ]),
    ...mapActions('login', [
      'fetchConfig',
    ]),
    onDatePickerInput(value) {
      this.dateFilter.start = value.start
      this.dateFilter.end = value.end
      this.getEventCheckins()
    },
    async getEventCheckins(params = {}) {
      this.loadingData = true
      await this.fetchCheckins({...this.getParams(), ...params})
      this.loadingData = false
    },
    getParams() {
      let params = {}
      if (this.hasPerm('checkin.fields.created_at')) {
        params.from = this.dateFilter.start || undefined
        params.to = this.dateFilter.end || undefined
        params.tz = this.timeZone
      }
      if (this.hasAnyPerms(['checkin.fields.email', 'checkin.fields.pica_code'])) {
        if (this.searchText) params.pcode_or_email = this.searchText
      }
      if (this.sorterValue.column) params.ordering = `${this.sorterValue.asc ? '' : '-'}${this.sorterValue.column}`
      params.no_photo = this.searchNoPhoto
      return params
    },
    routeToSearchPhotos(picacode) {
      return {name: 'MediaSearch', params: {picaServiceId: this.picaServiceId}, query: {picacode: picacode}}
    },
    routeToBrowsePhotos(picacode) {
      return {name: 'photoSearch.search', params: {picaServiceId: this.picaServiceId}, query: {pica_code: picacode}}
    },
    async doExportCheckins() {
      await this.exportCheckins(this.getParams())
    }
  }
}
</script>