import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate

export const checkinsFields = {
    id: {
        key: 'id',
        label: 'ID',
        _style: 'width:5%;',
        sorter: true,
    },
    created_at: {
        key: 'created_at',
        label: $gettext('Created at'),
        sorter: true,
        _style: 'min-width:175px'
    },
    // 'created_at_utc': {
    //     key: 'created_at_utc',
    //     label: 'Created at UTC',
    //     sorter: true,
    //     _style: 'min-width:175px'
    // },
    pica_code: {
        key: 'picacode',
        label: $gettext('Pica code'),
        sorter: true,
    },
    email: {
        key: 'email',
        label: $gettext('Email'),
        sorter: true,
    },
    tags: {
        key: 'tags',
        label: $gettext('Tags'),
        sorter: false,
    },
    is_master: {
        key: 'is_master',
        label: $gettext('Is Master'),
        sorter: true,
        _style: 'width:130px'
    },
    date_of_stay: {
        key: 'date_of_stay',
        label: $gettext('Date of stay'),
        sorter: false,
        _style: 'min-width:175px'
    },
    available_photos_number: {
        key: 'available_photos_number',
        label: $gettext('Available photos'),
        sorter: false,
        _style: 'text-align:right;min-width:100px;'
    },
    actions: {
        key: 'actions',
        label: $gettext('Actions'),
        _style: 'width:130px'
    },
}